<template>
  <div class="fixed w-full h-full -z-1">
    <div
      class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
      ref="el"
      :style="styles"
    ></div>
  </div>
</template>

<script lang="ts" setup>
import debounce from 'lodash/debounce';
import lottie, { AnimationItem } from 'lottie-web';
import data from '~~/assets/lottie/bg2/data.json';

const styles = ref<{ width?: string; height?: string }>({
  width: undefined,
  height: undefined,
});
const el = ref<HTMLElement>();
let animation: AnimationItem;

const updateStyles = () => {
  const lottieWidth = 1440;
  const lottieHeight = 792;
  const ratio = lottieWidth / lottieHeight;
  const windowRatio = innerWidth / innerHeight;
  let width;
  let height;

  if (ratio > windowRatio) {
    width = (innerHeight / lottieHeight) * lottieWidth;
    height = innerHeight;
  } else {
    width = innerWidth;
    height = (innerWidth / lottieWidth) * lottieHeight;
  }

  styles.value = {
    width: `${width}px`,
    height: `${height}px`,
  };
};
const updateStylesDebounced = debounce(updateStyles, 100);

onMounted(() => {
  if (!el.value) return;

  const animationData = {
    ...data,
    // Update asset paths.
    assets: data.assets.map((asset) => {
      return asset.p
        ? {
            ...asset,
            p: `/assets/lottie/bg2/images/${asset.p}`,
            u: '',
          }
        : asset;
    }),
  };

  animation = lottie.loadAnimation({
    container: el.value,
    renderer: 'svg',
    loop: true,
    autoplay: true,
    animationData,
  });

  updateStyles();
  window.addEventListener('resize', updateStylesDebounced);
});

onBeforeUnmount(() => {
  animation.destroy();
  window.removeEventListener('resize', updateStylesDebounced);
});
</script>
